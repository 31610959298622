<template>
  <div class="manage-exclusive-submissions">
    <div class="container fluid">
      <h1 class="m-2 mb-5">Manage Exclusive Submissions</h1>

      <!-- Filter -->
      <filter-panel
        v-model="isFilterActive"
        useToggle
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-select
            v-model="filter['status:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Status"
            :showEmptyChoice="false"
            :options="statusOptions"
          >
          </fd-select>
        </div>
      </filter-panel>

      <!-- Table -->
      <vue-good-table
        mode="remote"
        class="card"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-row-click="(e) => openDetailModal(e.row)"
        :columns="tableColumns"
        :rows="tableData"
        :totalRows="tablePagination.total"
        :isLoading.sync="isTableLoading"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
      >
        <template #emptystate>
          <no-data message="There is no exclusive submission."></no-data>
        </template>
        <template #loadingContent>
          <div class="d-flex justify-content-center">
            <spinner></spinner>
          </div>
        </template>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" @click.stop>
            <template v-if="props.row.status.id == STATUS.PENDING">
              <fd-button
                class="flat main lg-mx-1 mt-1"
                @click="promptApprove(props.row.id)"
              >
                Approve
              </fd-button>
              <fd-button
                class="flat danger lg-mx-1 mt-1"
                @click="promptReject(props.row.id)"
              >
                Reject
              </fd-button>
            </template>
          </span>
          <span v-if="props.column.field == 'submissionStatus'">
            <ExclusiveSubmissionStatus
              :status="props.row.status"
            ></ExclusiveSubmissionStatus>
          </span>
          <span v-else-if="props.column.field == 'propertySummary'">
            <property-summary :property="props.row.property"></property-summary>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <!-- Modal: Details -->
    <modal v-model="isModalShown">
      <modal-content class="card">
        <template #body>
          <div class="p-2 sm-p-3">
            <div class="d-flex justify-content-end">
              <fd-button
                class="bordered main"
                @click="
                  $router.push({
                    name: 'ManagePropertyDetail',
                    params: {
                      id: exclusiveToShow.property.id
                    }
                  })
                "
              >
                <i class="fas fa-external-link"></i>
              </fd-button>
            </div>
            <!-- Property -->
            <property-summary
              :property="exclusiveToShow.property"
              owner
            ></property-summary>
            <!-- Actions -->
            <div
              v-if="exclusiveToShow.status.id == STATUS.PENDING"
              class="d-flex justify-content-end mt-3"
            >
              <fd-button class="main" @click="promptApprove(exclusiveToShow.id)"
                >Approve</fd-button
              >
              <fd-button
                class="bordered danger ml-2"
                @click="promptReject(exclusiveToShow.id)"
                >Reject</fd-button
              >
            </div>
            <!-- Expiry & Remark -->
            <div class="mt-2">
              <display-data
                label="Expiry Date"
                :content="exclusiveToShow.exclusiveUntilDate"
              ></display-data>
              <display-data
                v-if="
                  exclusiveToShow.status.id == STATUS.APPROVED ||
                  exclusiveToShow.status.id == STATUS.REJECTED
                "
                label="Remark"
                :content="
                  exclusiveToShow.status.id == STATUS.APPROVED
                    ? exclusiveToShow.approveRemark
                    : exclusiveToShow.rejectRemark
                "
              ></display-data>
            </div>
            <!-- Download Supporting Docs -->
            <download-files
              label="Supporting Documents"
              class="col-12 mt-2 p-2"
              :downloadItem="exclusiveToShow.supportedDocuments"
              :filenamePrefix="`exclusive-doc-${exclusiveToShow.id}`"
            >
            </download-files>
          </div>
        </template>
        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button @click="isModalShown = false"> Close </fd-button>
          </div>
        </template>
      </modal-content>
    </modal>
    <!-- Modal: Confirm Action -->
    <modal v-model="isConfirmModalShown">
      <ExclusiveSubmissionActionContent
        :type="confirmType"
        @close="isConfirmModalShown = false"
        @approve="approveExclusive"
        @reject="rejectExclusive"
      ></ExclusiveSubmissionActionContent>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { exclusiveListing as exclusiveAPI } from "@/api";
import { exclusiveSubmissionStatus as STATUS } from "@/enums";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles"),
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    ExclusiveSubmissionStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/Property/ExclusiveSubmissionStatus"
      ),
    ExclusiveSubmissionActionContent: () =>
      import("@/components/Exclusive/ExclusiveSubmissionActionContent")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      idToConfirm: "",
      confirmType: "Approve",
      isConfirmModalShown: false,
      isModalShown: false,
      exclusiveToShow: {},
      // Table
      isTableLoading: false,
      tableData: [],
      tableColumns: [
        {
          label: "Actions",
          field: "actions",
          width: "15%"
        },
        {
          label: "Status",
          field: "submissionStatus"
        },
        {
          label: "Property",
          field: "propertySummary"
        },
        {
          label: "Expiry Date",
          field: "exclusiveUntilDate",
          formatFn: (val) => this.$dayjs(val, "YYYY-MM-DD").format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      tablePagination: {
        total: 0
      },
      // Filter
      STATUS,
      statusOptions: [],
      isFilterActive: false,
      filter: {
        "status:id": STATUS.PENDING
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getStatusOptions();
      this.getExclusiveSubmissions();
    },
    openDetailModal(exclusive) {
      this.exclusiveToShow = exclusive;
      this.isModalShown = true;
    },
    promptApprove(id) {
      this.idToConfirm = id;
      this.confirmType = "Approve";
      this.isConfirmModalShown = true;
    },
    promptReject(id) {
      this.idToConfirm = id;
      this.confirmType = "Reject";
      this.isConfirmModalShown = true;
    },
    // ============================ Table Functions ============================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getExclusiveSubmissions();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getExclusiveSubmissions();
    },
    // ============================ Filter Function ============================
    filterData() {
      this.isTableLoading = true;
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getExclusiveSubmissions().then(() => {
        this.isTableLoading = false;
      });
    },
    clearFilter() {
      this.isTableLoading = true;
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getExclusiveSubmissions().then(() => {
        this.isTableLoading = false;
      });
    },
    // ======================== API Interaction Related ========================
    async getExclusiveSubmissions() {
      try {
        this.isTableLoading = true;
        let res = await exclusiveAPI.getExclusiveSubmissions({
          queries: { ...this.filter },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });
        this.tableData = res.data;
        this.tablePagination = res.meta.pagination;
        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch exclusive submissions. Please try again later."
        });
      }
    },
    async getStatusOptions() {
      let data = await exclusiveAPI.getStatus();
      this.statusOptions = this._.cloneDeep(data);
    },
    async approveExclusive(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await exclusiveAPI.approveExclusive(this.idToConfirm, payload);
        this.$store.commit("setIsLoading", false);
        this.isConfirmModalShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Exclusive submission has been approved."
        });
        await this.getExclusiveSubmissions();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to approve exclusive after interacting with server. Please try again later."
        });
      }
    },
    async rejectExclusive(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await exclusiveAPI.rejectExclusive(this.idToConfirm, payload);
        this.$store.commit("setIsLoading", false);
        this.isConfirmModalShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Exclusive submission has been rejected."
        });
        this.getExclusiveSubmissions();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to reject exclusive after interacting with server. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
