var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-exclusive-submissions"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"m-2 mb-5"},[_vm._v("Manage Exclusive Submissions")]),_c('filter-panel',{attrs:{"useToggle":""},on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Status","showEmptyChoice":false,"options":_vm.statusOptions},model:{value:(_vm.filter['status:id']),callback:function ($$v) {_vm.$set(_vm.filter, 'status:id', $$v)},expression:"filter['status:id']"}})],1)]),_c('vue-good-table',{staticClass:"card",attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"isLoading":_vm.isTableLoading,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":function (e) { return _vm.openDetailModal(e.row); },"update:isLoading":function($event){_vm.isTableLoading=$event},"update:is-loading":function($event){_vm.isTableLoading=$event}},scopedSlots:_vm._u([{key:"emptystate",fn:function(){return [_c('no-data',{attrs:{"message":"There is no exclusive submission."}})]},proxy:true},{key:"loadingContent",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(props.row.status.id == _vm.STATUS.PENDING)?[_c('fd-button',{staticClass:"flat main lg-mx-1 mt-1",on:{"click":function($event){return _vm.promptApprove(props.row.id)}}},[_vm._v(" Approve ")]),_c('fd-button',{staticClass:"flat danger lg-mx-1 mt-1",on:{"click":function($event){return _vm.promptReject(props.row.id)}}},[_vm._v(" Reject ")])]:_vm._e()],2):_vm._e(),(props.column.field == 'submissionStatus')?_c('span',[_c('ExclusiveSubmissionStatus',{attrs:{"status":props.row.status}})],1):(props.column.field == 'propertySummary')?_c('span',[_c('property-summary',{attrs:{"property":props.row.property}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('modal',{model:{value:(_vm.isModalShown),callback:function ($$v) {_vm.isModalShown=$$v},expression:"isModalShown"}},[_c('modal-content',{staticClass:"card",scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"p-2 sm-p-3"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('fd-button',{staticClass:"bordered main",on:{"click":function($event){return _vm.$router.push({
                  name: 'ManagePropertyDetail',
                  params: {
                    id: _vm.exclusiveToShow.property.id
                  }
                })}}},[_c('i',{staticClass:"fas fa-external-link"})])],1),_c('property-summary',{attrs:{"property":_vm.exclusiveToShow.property,"owner":""}}),(_vm.exclusiveToShow.status.id == _vm.STATUS.PENDING)?_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('fd-button',{staticClass:"main",on:{"click":function($event){return _vm.promptApprove(_vm.exclusiveToShow.id)}}},[_vm._v("Approve")]),_c('fd-button',{staticClass:"bordered danger ml-2",on:{"click":function($event){return _vm.promptReject(_vm.exclusiveToShow.id)}}},[_vm._v("Reject")])],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('display-data',{attrs:{"label":"Expiry Date","content":_vm.exclusiveToShow.exclusiveUntilDate}}),(
                _vm.exclusiveToShow.status.id == _vm.STATUS.APPROVED ||
                _vm.exclusiveToShow.status.id == _vm.STATUS.REJECTED
              )?_c('display-data',{attrs:{"label":"Remark","content":_vm.exclusiveToShow.status.id == _vm.STATUS.APPROVED
                  ? _vm.exclusiveToShow.approveRemark
                  : _vm.exclusiveToShow.rejectRemark}}):_vm._e()],1),_c('download-files',{staticClass:"col-12 mt-2 p-2",attrs:{"label":"Supporting Documents","downloadItem":_vm.exclusiveToShow.supportedDocuments,"filenamePrefix":("exclusive-doc-" + (_vm.exclusiveToShow.id))}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end p-2"},[_c('fd-button',{on:{"click":function($event){_vm.isModalShown = false}}},[_vm._v(" Close ")])],1)]},proxy:true}])})],1),_c('modal',{model:{value:(_vm.isConfirmModalShown),callback:function ($$v) {_vm.isConfirmModalShown=$$v},expression:"isConfirmModalShown"}},[_c('ExclusiveSubmissionActionContent',{attrs:{"type":_vm.confirmType},on:{"close":function($event){_vm.isConfirmModalShown = false},"approve":_vm.approveExclusive,"reject":_vm.rejectExclusive}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }